.footer {
    background: #ededed;
    position: relative;
    margin-top: 0;
    padding: 2em 0 1.5em;
    border-top: 1px solid #d9d9d9;
    max-width: 100%;
}

.footer-inline-list {
    display: block;
    list-style: none;
    margin-top: 0;
    margin-bottom: 0.94444rem;
    margin-left: auto;
    margin-right: auto;
    overflow: hidden;
    padding: 0;
    text-align: center;
    font-size: large;
    color:#176DA6;
}

.footer-inline-list li {
    display: inline-block;
    font-weight: bold;
    font-size: large;
}

a:link {
    color:#176DA6 ;
    background-color: transparent;
    text-decoration: none;
  }
  
  a:visited {
    color: #1770AB;
    background-color: transparent;
    text-decoration: none;
  }
  

.asterix {
    color: #9C1028;
    outline: none;
    font-weight: bolder;
}

label {
    font-size: 100%;
}

legend {
    /*font-weight: bold;*/
    margin-bottom: 1rem;
}

.bold {
    font-weight: bold;
}

.error {
    background-color: #9C1028;
    padding: 5px;
    font-style: italic;
    color: white;
    position: relative;
    top: -1rem;
}

.disabled {
    color: #ababab;
}

.form-header {
    background-color: #0093d6;
    background-image: url("/missions/images/Isolatedpoint_yellow.svg");
    background-size: 20% auto;
    background-repeat: no-repeat;
    background-position: bottom right;
    color: #ffffff;
    display: block;
    overflow-x: hidden;
}

.smaller {
    font-size: 85%;
}

.map_link {
    background-color: #242121;
    padding: 5px;
    border-radius: 8px;
    color: #ffffff;
}

.map_link a {
    color: #ffffff;
}

.opt_in {
    zoom: 3.6;
    border-radius: 0 !important;
    vertical-align: top;
    margin-top: .1rem;
}

.opt_in_title {
    margin-left: 1rem;
    display: inline-block;
    width: calc(100% - 6rem);
}

#submit {
    text-transform: uppercase !important;
}

.chosen-container {
    width: 100% !important;
}

.lang_switcher abbr, .lang_switcher abbr[title] {
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
    border-bottom: 0px !important;
}

.multi_checkbox [type="checkbox"] {
    vertical-align: top !important;
    margin-top: 6px !important;
}

.multi_checkbox label {
    width: calc(100% - 3rem) !important;
}

.words {
    font-size: 12px;
    text-align: right;
    color: #000000;
    margin: -1rem 0 1rem 0;
    padding: 0 10px;
    display: block;
    background-color: beige;
    width: auto;
}

.response {
    font-size: 1.4em;
}

.contact-email {
    display: none;
}

.mission_reporting {
    border: 1px solid #bbb;
    padding-top: 15px;
    padding: 20px;
    margin: 20px 0px;
}

input#terms_read {
    zoom: 1.75;
    margin: 0;
}

label#terms_read_title {
    display: inline;
}

.scrollbox {
    background-color: #ffffff;
    border: 1px solid #cacaca;
    height: 300px;
    overflow: auto;
    margin-left: auto;
    margin-right: auto;
    max-width: 73.5rem;
}

.invalid {
    color: #bf1722;
}

/*.valid:after {
    content: " ✔";
    color: green;
}*/

a {
    color: revert;
}

pre {
    font-size: inherit;
    color: inherit;
    border: initial;
    padding: initial;
    font-family: inherit;
    white-space: pre-wrap;       /* Since CSS 2.1 */
    white-space: -moz-pre-wrap;  /* Mozilla, since 1999 */
    white-space: -pre-wrap;      /* Opera 4-6 */
    white-space: -o-pre-wrap;    /* Opera 7 */
    word-wrap: break-word;       /* Internet Explorer 5.5+ */
}

.border-box {
    position: relative;
    margin: 0 0 1rem 0;
    padding: 0.5rem;
    border: 1px solid rgba(10, 10, 10, .25);
    border-radius: 0;
    background-color: #fff;
    color: #0a0a0a;
}

input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
    /* display: none; <- Crashes Chrome on hover */
    -webkit-appearance: none;
    margin: 0;
    /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
    -moz-appearance: textfield;
    /* Firefox */
}

/*
[type='text'], 
[type='date'],
[type='email'],
[type='number'],
[type='tel'],
[type='url'], 
textarea,
select {
  margin: 0;
}*/

.hidden {
    display: none;
}

.alert-callout.success {
    border: 0 solid #3adb76;
    border-left-width: 0.3125rem;
    padding: 1.5rem;
}

.reg-summary {
    margin: 0.5rem;
}